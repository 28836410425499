<template>
    <div class="panel panel-default">
            <div class="panel-heading"></div>
            <div class="row panel-body">
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Company</label>
                    <Typeahead cdi_type="company" v-bind:ID.sync="state.optionFields.companyID.val" />
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Customer</label>
                    <Typeahead cdi_type="user" v-bind:ID.sync="state.optionFields.distributor.val" />
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>CDI Location</label>
                    <select class="cdi_select form-control" v-model="state.optionFields.cdiLocationID.val">
                        <option value="0">All</option>
                        <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID" :value="cdiLocationID">{{ cdiLocation }}</option>
                    </select>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Regional Sales Rep</label>
                    <Typeahead cdi_type="sales_employee" v-bind:ID.sync="state.optionFields.salesUserID.val" />
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Sales Support Rep</label>
                    <Typeahead cdi_type="sales_employee" v-bind:ID.sync="state.optionFields.insideSalesUserID.val" />
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                  <label>National Sales Team</label>
                  <select class="form-control" v-model="state.optionFields.nationalSalesTeams.val">
                    <option value="0"></option>
                    <option v-for="[id, location] in nationalSalesTeams" v-bind:key="id" :value="id">{{ location }}</option>
                  </select>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                    <label><input type="checkbox" v-model="state.optionFields.targetedCustomer.val" :true-value="1" :false-value="0"> Targeted Customer</label>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                    <label><input type="checkbox" v-model="state.optionFields.enabledOnly.val" :true-value="1" :false-value="0" > Enabled Customers Only</label>
                </div>
            </div>
        </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import Typeahead from "@/components/utils/typeaheads/Typeahead";

    export default {
        name: "customer_summary_opt",
        components: {Typeahead},
        data() {
            return {
                state: store.state,
                toggleHide: true,
                cache: {
                    cdiLocationCache,
                },
              nationalSalesTeams: [
                [0,'Options Not Available']],
            }
        },
      created() {
        this.get_national_sales_teams();
      },
        methods: {
            toggleMoreFilters: function () {
                this.toggleHide = !this.toggleHide;
            },
          get_national_sales_teams: function() {
            store.customAPI('menu_builder/national_sales_teams').then(res => this.nationalSalesTeams = res);
          }
        }
    }
</script>

<style scoped>

</style>